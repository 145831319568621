<template>
  <div>
    <!-- 搜索 -->
    <div class="search-box">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="标题">
          <el-input
            v-model="queryForm.title"
            placeholder="请输入标题"
            size="small"
            @input="queryContent"
            clearable
            @clear="queryContent"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布人">
          <el-input
            v-model="queryForm.authorName"
            placeholder="请输入发布人"
            size="small"
            @input="queryContent"
            clearable
            @clear="queryContent"
          ></el-input>
        </el-form-item>

          <el-form-item label="栏目">
                <el-select
                  ref="rlueFormTreeSelect"
                  v-model="ruleForm.channelName"
                  placeholder="请选择栏目"
                  fit-input-width="true"
                  style="width: 100%"
                  clearable
                  @clear="refreshQueryContent"
                  @change="queryContent"
                  size="small"
                >
                  <el-option style="height: auto; padding: 0">
                    <el-tree
                      expand-on-click-node="false"
                      default-expand-all
                      ref="tree"
                      highlight-current="true"
                      :data="dataTree"
                      node-key="id"
                      :props="{ class: customNodeClass }"
                      @node-click="indexHandleNodeClick"
                    />
                  </el-option>
                
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select
            style="width: 100px"
            v-model="queryForm.status"
            placeholder="请选择"
            clearable
            @clear="queryContent"
            @change="queryContent"
            size="small"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="queryForm.releageTime"
            type="date"
            placeholder="选择发布时间"
            value-format="YYYY-MM-DD"
            @change="queryContent"
            clearable
            @clear="queryContent"
            size="small"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryContent" size="small"
            >查询</el-button
          >
          <el-button v-show="hasAddPermision" icon="el-icon-plus" @click="addInfo" size="small"
            >新增内容</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 内容列表 -->
    <div class="data-box">
      <el-table
        v-loading="loading"
        border
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        row-key="id"
        default-expand-all
        :data="tableData"
        style="width: 100%"
        height="800px"
      >
        <el-table-column :show-overflow-tooltip="true" prop="title" label="标题" width="400" align="center" >
          <template #default="scope">
            <el-link title="点击预览" target="_blank" :href="'#/content?contentId='+scope.row.id+'&channelName='+scope.row.channelName+'&channelId='+scope.row.channelId">{{scope.row.title}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
            prop="clicks"
            label="浏览量"
            width="80"
            align="center"
        />
        <el-table-column
          prop="authorName"
          label="发布人"
          width="120"
          align="center"
        />
        <el-table-column
          prop="channelName"
          label="栏目"
          width="150"
          align="center"
        />
        <el-table-column prop="seq" label="排序" width="80" align="center" >
          <template #default="scope">
            <el-input class="dcNo_sty"  size="small" v-model="scope.row.seq" placeholder="排序"
            @change="rowEditSeq(scope.$index, scope.row)"></el-input> 
            <span>{{scope.row.seq}}</span>
         </template>
        </el-table-column>
        <el-table-column prop="isTop" label="置顶" width="100" align="center">
          <template #default="scope">
            <el-switch
              class="el-switch-my"
              v-model="scope.row.isTop"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              @change="changeStatus($index, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          label="发布时间"
          align="center"
          width="180"
        />
        <el-table-column
          prop="downTime"
          label="下架时间"
          align="center"
          width="180"
        />
        <el-table-column prop="status" label="状态" align="center">
          <template #default="scope">
            <el-tag
              :type="
                scope.row.status === 0
                  ? 'warning'
                  : scope.row.status === 1
                  ? ''
                  : 'info'
              "
              disable-transitions
              >{{
                scope.row.status === 0
                  ? "草稿"
                  : scope.row.status === 1
                  ? "上架"
                  : "下架"
              }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="220"  v-if="hasDeletePermision||hasEditPermision || hasUpPermision || hasDownPermision || hasPushPermision">
          <template #default="scope">
            <el-button v-show="hasEditPermision" size="mini" @click="editInfo(scope.$index, scope.row)"
              >编辑</el-button
            >
<!--            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'推送【'+scope.row.title.substring(0,10)+'...】内容吗?'"
              @confirm="pushContent(scope.$index, scope.row)">
            <template #reference>
              <el-button v-show="hasPushPermision" size="mini" type="success">推送</el-button>
            </template>
            </el-popconfirm>-->

            <el-button
              v-show="hasDownPermision"
              size="mini"
              type="danger"
              @click="upDownContent(scope.$index, scope.row, 2)"
              v-if="scope.row.status == 1"
              >下架</el-button
            >
            <el-button
            v-show="hasUpPermision"
              size="mini"
              type="primary"
              @click="upDownContent(scope.$index, scope.row, 1)"
              v-if="scope.row.status == 2 || scope.row.status == 0"
              >上架</el-button
            >

            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除内容吗?'"
              @confirm="deleteContent(scope.$index, scope.row)">
            <template #reference>
              <el-button v-show="hasDeletePermision" size="mini" type="danger">删除</el-button>
            </template>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-box">
      <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 内容新增、修改dialog -->
    <div>
      <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        v-model="dialogVisible"
        :title="dialogTitle"
        width="1158px"
        top="30px"
        @close="handleClose"
        :fullscreen="fullscreen"
        destroy-on-close
      >
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="80px"
          class="demo-ruleForm"
          style="overflow-y: hidden;overflow-x: hidden;"
        >
          <el-row :gutter="10">
            <el-col :span="15">
              <el-form-item label="标题" prop="title" class="label-color">
                <el-input
                  v-model="ruleForm.title"
                  placeholder="请输入标题"
                  clearable
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="选择栏目" prop="channelId" class="label-color">
                <el-select
                  ref="rlueFormTreeSelect"
                  v-model="ruleForm.channelName"
                  placeholder="请选择栏目"
                  fit-input-width="true"
                  style="width: 100%"
                >
                  <el-option style="height: auto; padding: 0">
                    <el-tree
                      expand-on-click-node="false"
                      default-expand-all
                      ref="tree"
                      highlight-current="true"
                      :data="dataTree"
                      node-key="id"
                      :props="{ class: customNodeClass }"
                      @node-click="handleNodeClick"
                    />
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row :gutter="10">
            <el-col :span="5">
              <el-form-item label="状态" prop="status" class="label-color">
                <el-select
                  v-model="ruleForm.status"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="排序" prop="seq" class="label-color">
                <el-input-number
                    style="width: 130px"
                  v-model="ruleForm.seq"
                  @change="handleChange"
                  :min="1"
                  :max="100000"
                ></el-input-number>
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="是否置顶" prop="isTop" class="label-color">
                <el-switch
                  v-model="ruleForm.isTop"
                  active-color="#13ce66"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </el-form-item>
            </el-col>

            <el-col :span="9">
              <el-form-item label="发布时间" prop="time" class="label-color">
                <el-date-picker
                    style="width: 100%"
                  v-model="ruleForm.time"
                  type="datetime"
                  placeholder="选择发布时间"
                  value-format="YYYY-MM-DD HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="缩略图" class="label-color">
            <el-row :span="24">
              <div>
                <el-upload
                    class="avatar-uploader"
                    :action="thumbnailAction"
                    :on-change="uploadImage"
                    :show-file-list="false"
                    :before-upload="beforeThumbnailUpload">
                  <img v-if="ruleForm.thumbnail!='' && ruleForm.thumbnail!=undefined" :src="ruleForm.thumbnail" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div style="margin-left: 5px;">
                <div style="margin-top:20px;"><el-button  icon="el-icon-zoom-in" @click="ruleForm.thumbnail!=''?dialogThumbnail = true:''" circle></el-button></div>
                <div style="margin-top:20px;"><el-button icon="el-icon-delete" @click="ruleForm.thumbnail =''" circle></el-button></div>
              </div>
            </el-row>
<!--            <el-link :underline="false" type="primary">{{picTip}}</el-link>-->
          </el-form-item>
          <el-row>
            <el-col>
              <el-form-item label="内容详情" prop="content" class="label-color">
                <editor-bar
                    style="width: 100%;  float: left;"
                    v-model="ruleForm.content"
                    :stickyHeight="410"
                    :content="ruleForm.content"
                    :isClear="isClear"
                    @change="changeContent"
                  ></editor-bar>
              </el-form-item>
              
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="fullscreen = !fullscreen" type="success">{{fullscreen?'取消最大化':'最大化'}}</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveInfo" v-if="isSave == true"
              >保存</el-button
            >
            <el-button type="primary" @click="updateInfo" v-if="isSave == false"
              >修改</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>

    <el-dialog title="图片预览" width="1000px"  v-model="dialogThumbnail" :show-close="false" append-to-body>
      <div class="preview">
        <img :src="ruleForm.thumbnail" alt="">
      </div>
    </el-dialog>

  </div>
</template>

<script>
import contentApi from "@/api/info/content";
import channelApi from "@/api/info/channel";
import { handleTree } from "@/utils/treeUtil";
import cookie from "@/utils/cookie";
import EditorBar from './Editor.vue';
import config from '@/api/config'
import replaceEditorImgURL from '@/utils/replaceEditorImgURL'
import imageUtil from '@/utils/imageUtil'
import dateUtil from '@/utils/dateUtil'
export default {
  //editor-bar 
  components: { EditorBar },
  data() {
    return {
      picTip:'(宽高比5:3为最佳显示效果)',
      fullscreen:false,
      hasAddPermision:false,
      hasEditPermision:false,
      hasDeletePermision:false,
      hasUpPermision: false,
      hasDownPermision: false,
      hasPushPermision: false,
      isClear:false,
      queryForm: {
        title: "",
        authorName: "",
        top: "",
        status: "",
        releageTime: "",
        channelId:''
      },
      statusOptions: [
        {
          value: 0,
          label: "草稿",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      loading: false,
      tableData: [],
      total: 0,
      pageSize: 15,
      curPage: 1,
      dialogVisible: false,
      dialogTitle: "",
      isSave: false,
      ruleForm: {
        id:'',
        title: '',
        channelName: '',
        channelId: '',
        content: '',
        authorId:'',
        time: '',
        isTop: 0,
        seq: '',
        status: '',
        thumbnail:'',
      },
      rules: {
        title: {
          required: true,
          message: "请输入标题",
          trigger: "blur",
        },
        channelId: [
          {
            required: true,
            message: "请选择栏目",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "blur",
          },
        ],
        time: [
          {
            required: true,
            message: "请选择发布时间",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
      dataTree: [],
      thumbnailAction:'',  //上传缩略图服务地址
      dialogThumbnail: false,  
      dialogImageUrl:'',
    };
  },
  mounted() {
      this.$nextTick(()=> {
        // 获取设置按钮权限
        this.hasAddPermision = this.$menuUtil.hasMenu('/info/contentManage:add');
        this.hasEditPermision = this.$menuUtil.hasMenu('/info/contentManage:edit');
        this.hasDeletePermision = this.$menuUtil.hasMenu('/info/contentManage:delete');
        this.hasUpPermision = this.$menuUtil.hasMenu('/info/contentManage:up');
        this.hasDownPermision = this.$menuUtil.hasMenu('/info/contentManage:down');
        this.hasPushPermision = this.$menuUtil.hasMenu('/info/contentManage:push');
    })
    this.queryContent();
    this.queryChannelTree();    
  },
  methods: {
    uploadImage(file, fileList){
      imageUtil.getBase64(file.raw).then(res =>{
        this.ruleForm.thumbnail = res;
      })
    },    

    beforeThumbnailUpload(file) {
        let types = ['image/jpeg', 'image/jpg', 'image/png'];
        const isJPG = types.includes(file.type);
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPEG、JPG、PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
    },

    //删除内容
    deleteContent(index, row){
      contentApi.deleteContent(row.id).then(response => {
        if(response.data.code == 1) {
          this.$notify({
              title: response.data.msg,
              type: 'success',
              offset:40,
              duration: 2000
            });
            //删除当前行
            this.tableData.splice(index,1);
        }else if(response.data.code == 0){
         this.$notify({
              title:response.data.msg,
              type: 'error',
              offset:40,
              duration: 2000
            });
           return;
         }
      })
    },

    //行内编辑
    rowEditSeq(index, row){
      contentApi.rowEditSeq({id: row.id, seq: row.seq}).then(response =>{
         if(response.data.code == 1){
           this.$notify({
              title: response.data.msg,
              type: 'success',
              offset:40,
              duration: 2000
            });
            this.queryContent();
         }else if(response.data.code == 0){
         this.$notify({
              title:response.data.msg,
              type: 'error',
              offset:40,
              duration: 2000
            });
           return;
         }
       })
      
    },

    //根据栏目选择器
    refreshQueryContent(){
      this.queryForm.channelId = '';
      this.ruleForm.channelId = '';
      this.queryContent();
    },
    changeContent(val){
      // val = replaceEditorImgURL.replaceDuplicatePicture(val);
      // console.log('conetent manager', val)
      this.ruleForm.content = val;
    },
    // 新增内容
    saveInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          var user = JSON.parse(cookie.getCookie("user"));
          this.ruleForm.authorId = user.id;
          if(this.ruleForm.content.indexOf(config.server) != -1){
            this.ruleForm.content = this.ruleForm.content.replaceAll(config.server, '')
          }
          this.ruleForm.thumbnail = (this.ruleForm.thumbnail != '' && this.ruleForm.thumbnail != undefined)?this.ruleForm.thumbnail.replaceAll(config.server, ''):'';
          contentApi.addContent(this.ruleForm).then((res) => {
            if (res.data.code == 1) {
              this.$notify({
                title: res.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });

              // 关闭窗口
              this.dialogVisible = false;
              this.ruleForm={}

              //刷新
              this.queryContent();
            } else {
              this.$notify.info({
                title: res.data.msg,
                type: "warning",
                offset: 40,
                duration: 3000,
              });
            }
          });
        }
      });
    },
    //上、下架内容
    upDownContent(index, row, status){
      var strInfo = status==1?'上架':'下架';
      this.$messageBox.confirm(
          '确定'+strInfo+'内容['+  row.title +']吗?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        contentApi.upDownContent(row.id, status).then(response=>{
          if(response.data.code==1){
            this.$notify({
              title: response.data.msg,
              type: 'success',
              offset:40,
              duration: 2000
            });
            this.queryContent();
          }else{
            this.$notify.info({
              title: response.data.msg,
              type: 'warning',
              offset:40,
              duration: 3000
            });
          }
        });
      }).catch(() => {
      })
    },
    //内容修改
    updateInfo(){
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
           if(this.ruleForm.content.indexOf(config.server) != -1){
               this.ruleForm.content = this.ruleForm.content.replaceAll(config.server, '')
           }
          this.ruleForm.thumbnail = (this.ruleForm.thumbnail != '' && this.ruleForm.thumbnail != undefined) ?this.ruleForm.thumbnail.replaceAll(config.server, ''):'';
          contentApi.editContent(this.ruleForm).then((res) => {
            if (res.data.code == 1) {
               // 关闭窗口
               this.dialogVisible = false;
              this.$notify({
                title: res.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });

             
              this.ruleForm={}

              //刷新
              this.queryContent();
            } else {
              this.$notify.info({
                title: res.data.msg,
                type: "warning",
                offset: 40,
                duration: 3000,
              });
            }
          });
        }
      });
    },
    //置顶修改
    changeStatus(index, row) {
      contentApi.changeIsTop({ id: row.id, isTop: row.isTop }).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            title: res.data.msg,
            type: "success",
            offset: 40,
            duration: 2000,
          });
        } else {
          this.$notify.info({
            title: res.data.msg,
            type: "warning",
            offset: 40,
            duration: 3000,
          });
        }
      });
    },
    //查询栏目- tree
    queryChannelTree() {
      channelApi.indexQueryChannel({ channelName: "" }).then((res) => {
        this.dataTree = handleTree(res.data.data, "name");
      });
    },
    //新增、编辑父级栏目选择
    indexHandleNodeClick(data) {
      this.ruleForm.channelName = data.label;
      this.ruleForm.channelId = data.id;
      this.queryForm.channelId = data.id;
      this.$refs.rlueFormTreeSelect.blur(); // 让select失去焦点，收起下拉
      this.queryContent();
    },
    //新增、编辑父级栏目选择
    handleNodeClick(data) {
      // 墓碑展示修改图片上传提示
      if(data.label=='墓碑展示'){
        this.picTip='(宽高比4:5为最佳显示效果)';
      }else{
        this.picTip='(宽高比5:3为最佳显示效果)';
      }
      this.ruleForm.channelName = data.label;
      this.ruleForm.channelId = data.id;
      this.$refs.rlueFormTreeSelect.blur(); // 让select失去焦点，收起下拉
    },
    //新增内容dialog
    addInfo() {
      this.picTip='(宽高比5:3为最佳显示效果)';
      this.queryChannelTree();
      this.dialogTitle = "新增内容";
      this.isSave = true;
      this.ruleForm = {};
      this.ruleForm.seq = 1;
      this.ruleForm.time = dateUtil.formatDateTime(new Date());
      this.ruleForm.status = 1;
      // this.thumbnailAction = config.server+'/api/content/uploadFile';
      this.thumbnailAction = '';

      this.dialogVisible = true;
    },
    // 编辑dialog
    editInfo(index, row){
      this.queryChannelTree();
      
      this.dialogTitle = "编辑内容";
      this.isSave = false;

      this.ruleForm.id=row.id;
      this.ruleForm.title = row.title;
      this.ruleForm.channelId = row.channelId;
      this.ruleForm.channelName = row.channelName;
      this.ruleForm.status = row.status;
      this.ruleForm.seq = row.seq;
      this.ruleForm.time = row.time;
      this.ruleForm.isTop = row.isTop;
      this.ruleForm.content = replaceEditorImgURL.toReplaceEditorImgURL(row.content);
      this.ruleForm.thumbnail = (row.thumbnail != '' && row.thumbnail != null ) ? config.server + row.thumbnail : '';
      // this.thumbnailAction = config.server+'/api/content/uploadImg';
      this.thumbnailAction = '';

      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(row.channelId);
      });

      if(row.channelName=='墓碑展示'){
        this.picTip='(宽高比4:5为最佳显示效果)';
      }else{
        this.picTip='(宽高比5:3为最佳显示效果)';
      }
    },
    //每页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryContent();
    },
    //当前页
    handleCurrentChange(val) {
      this.curPage = val;
      this.queryContent();
    },
    //查询内容列表
    queryContent() {
      contentApi
        .queryContent({
          pageSize: this.pageSize,
          curPage: this.curPage,
          title: this.queryForm.title,
          authorName: this.queryForm.authorName,
          channelId: this.queryForm.channelId,
          status: this.queryForm.status,
          releageTime: this.queryForm.releageTime,
          
        })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    // 弹窗关闭事件
    handleClose(){
        this.ruleForm={};
      }
  },
};
</script>

<style scope>
  .dcNo_sty .el-input__inner{
    /*border:none;*/
    text-align: center;
    height: 30px;
  }
  .el-switch-my .el-switch__core .el-switch__action{
    height: 16px;
  }

  /* 上传 */
 .avatar-uploader .el-upload {
    width: 210px;
    height: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 130px;
    text-align: center;
  }
  .avatar {
    height: 130px;
    /* display: block; */
  }
  .preview{
    overflow: hidden;
  }
  .preview img{
    max-width: 1000px;
  }
</style>